import React, { useRef, useState } from "react";
import { usePermission } from "../../components/usePermission";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import UpdateIcon from "@material-ui/icons/Update";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import CategoryIcon from "@material-ui/icons/Category";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Add } from "@material-ui/icons";
import { Paper } from "@material-ui/core";

import SimpleBackdrop from "../../components/Control/BackDrop";
import Input from "../../components/Control/Input";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import Modal from "../../components/Control/Modal";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import Create from "./Create";

const TableList = () => {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const partId = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [sortBy, setSortBy] = useState("item_no");
  const [isView, setIsView] = useState(false);
  const [isAddEditModal, setIsAddEditModal] = useState(false);
  const [filter, setFilter] = useState("true");
  const [dropDownRender, setDropDownRender] = useState(false);
  const [loader, setLoader] = useState(false);
  const permissions = usePermission(8);
  //confirm modal
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE STATUS",
    "Are you sure you want to update this?",
  );
  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    "REMOVE",
    "Are you sure you want to remove this process?",
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/getMasterModule/data/?page=${page}&sortBy=${sortBy}&search=${search}&filter=${filter}&perPage=10`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.results);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    sortBy,
    isAddEditModal,
  ]);

  async function deletePart(id) {
    const isDelete = await confirmRemoveProcess();
    if (isDelete) {
      await axios.delete(`/api/deleteMasterModule/data/${id}`);
      setRender((prev) => !prev);
    }
  }

  const handleImport = (e) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post(`/api/importMasterModule/data`, formData)
      .then((res) => {
        if (res.data.isSuccess) window.alert("successfully uploaded");
        setLoader(false);
        setRender((prev) => !prev);
      })
      .catch((err) => {
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else if (
          err.response.data.error.message
            .split(" ")
            .join("")
            .toLowerCase()
            .includes("duplicatekeyerror")
        ) {
          window.alert(
            "Successfully uploaded. All the include parts has been skipped.",
          );
        } else {
          window.alert(
            "Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.",
          );
        }
        setLoader(false);
      });
  };

  const handleActiveInactive = async (partId, value) => {
    axios
      .patch(`/api/update/itemMasterPatch?part_id=${partId}`, {
        isActive: value,
      })
      .catch((err) => console.log(err));
    setRender((prev) => !prev);
  };

  return (
    <>
      <SimpleBackdrop open={loader} />
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          ITEM MASTER
          {permissions?.includes("edit") && (
            <Wrapper>
              <div style={{ marginTop: "9px", marginRight: "0.5rem" }}>
                <input
                  id="contained-button-file"
                  type="file"
                  name="excelFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleImport}
                  hidden
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    component="span"
                    color="secondary"
                    size="large"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <CloudUploadIcon style={{ marginRight: "1rem" }} />
                    Upload Item Master
                  </Button>
                </label>
              </div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setIsAddEditModal(true);
                }}
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Add />}
              >
                New Item
              </Button>
            </Wrapper>
          )}
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Item Number"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="filter"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setSortBy(e.target.value)}
                defaultValue={sortBy}
              >
                <MenuItem value="item_no">A - Z</MenuItem>
                <MenuItem value="createdAt">Most Recent First</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Description
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Weight / Pc (Kgs)
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Created At
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Active/Inactive
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.item_no}</TableCell>
                      <TableCell align="center">
                        {item.item_description}
                      </TableCell>
                      <TableCell align="center">{item.weight_pc}</TableCell>
                      <TableCell align="center">
                        {formatDate(item.date_created)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "250px",
                            width: "220px",
                            alignItems: "center",
                          }}
                        >
                          {permissions?.includes("edit") && (
                            <Tooltip title="Edit Part" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: ".5rem",
                                }}
                                onClick={() => {
                                  partId.current = item._id;
                                  setIsView(false);
                                  setIsAddEditModal(true);
                                }}
                              >
                                <EditIcon
                                  style={{ fontSize: "1.4rem", color: "black" }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Quick View" arrow>
                            {permissions?.includes("view") ? (
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.5rem",
                                }}
                                onClick={() => {
                                  partId.current = item._id;
                                  setIsView(true);
                                  setIsAddEditModal(true);
                                }}
                              >
                                <VisibilityIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#003566",
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <BlockIcon style={{ color: "#c92a2a" }} />
                            )}
                          </Tooltip>

                          {permissions?.includes("delete") && (
                            <Tooltip title="Delete Part" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.8rem",
                                }}
                                onClick={(e) => deletePart(item._id)}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <Wrapper justify="center" style={{ gap: "0.5rem" }}>
                          {permissions?.includes("edit") && (
                            <Button
                              variant="outlined"
                              color="white"
                              style={{
                                color: "#fafafa",
                                backgroundColor: item.isActive
                                  ? "#212529"
                                  : "#e63946",
                                fontSize: "0.68rem",
                              }}
                              onClick={() => {
                                handleActiveInactive(item._id, !item.isActive);
                              }}
                            >
                              {item.isActive ? "Inactive" : "Active"}
                            </Button>
                          )}
                        </Wrapper>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <DialogUpdate />
        <DialogRemoveProcess />
      </main>
      <Modal
        openPopup={isAddEditModal}
        title="CREATE ITEM MASTER"
        closeModal={() => {
          partId.current = null;
          setIsAddEditModal(false);
          setIsView(false);
        }}
      >
        <Create
          id={partId.current}
          isView={isView}
          setIsAddEditModal={setIsAddEditModal}
        />
      </Modal>
    </>
  );
};

export default TableList;
