import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth } from "../../context/GlobalContext";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import { usePermission } from "../../components/usePermission";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});
const EditIconButton = (state, documentType, item) => {
  const permissions = usePermission(6);

  if (documentType === "controlplan") {
    return (
      <>
        {permissions?.includes("edit") && (
          <Tooltip title="Edit" arrow>
            <Link
              to={`/createControlplanFormat/${documentType}/${item.annexure_type}/${item._id}`}
            >
              <IconButton
                size="small"
                style={{
                  marginLeft: "0.6rem",
                  color: "#161a1d",
                  fontSize: "1.4rem",
                }}
              >
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </>
    );
  }
  if (documentType === "fmea") {
    return (
      <>
        {permissions?.includes("edit") && (
          <Tooltip title="Edit" arrow>
            <Link
              to={`/createFmeaFormat/${documentType}/${item.annexure_type}/${item._id}`}
            >
              <IconButton
                size="small"
                style={{
                  marginLeft: "0.6rem",
                  color: "#161a1d",
                  fontSize: "1.4rem",
                }}
              >
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </>
    );
  }
  if (documentType === "pfd") {
    return (
      <>
        {permissions?.includes("edit") && (
          <Tooltip title="Edit" arrow>
            <Link
              to={`/createPfdFormat/${documentType}/${item.annexure_type}/${item._id}`}
            >
              <IconButton
                size="small"
                style={{
                  marginLeft: "0.6rem",
                  color: "#161a1d",
                  fontSize: "1.4rem",
                }}
              >
                <EditIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </>
    );
  }
};

const viewIconButton = (documentType, item) => {
  if (documentType === "controlplan") {
    return (
      <Tooltip title="ControlPlan" arrow>
        <Link to={`/viewControlplanFormat/${documentType}/${item._id}`}>
          <IconButton size="small">
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Link>
      </Tooltip>
    );
  }
  if (documentType === "fmea") {
    return (
      <Tooltip title="FMEA" arrow>
        <Link to={`/viewFmeaFormat/${documentType}/${item._id}`}>
          <IconButton size="small">
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Link>
      </Tooltip>
    );
  }
  if (documentType === "pfd") {
    return (
      <Tooltip title="PFD" arrow>
        <Link to={`/viewPfdFormat/${documentType}/${item._id}`}>
          <IconButton size="small">
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
          </IconButton>
        </Link>
      </Tooltip>
    );
  }
};
const documentTitle = {
  pfd: "LIST OF MASTER PROCESS FLOW DIAGRAM",
  controlplan: "LIST OF MASTER CONTROL PLAN",
  fmea: "LIST OF MASTER FMEA",
};

const THead = (
  <TableHead>
    <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        #
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Annexure Type
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Type
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Revision number
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Action
      </TableCell>
    </TableRow>
  </TableHead>
);
function MasterControlFmeaPfdList() {
  const { documentType } = useParams();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const [render, setRender] = useState(false);
  useEffect(() => {
    if (tabValue >= 0) {
      const annexureType = [
        "annexure-a",
        "annexure-b",
        "annexure-c",
        "annexure-d",
        "annexure-e",
      ];
      axios
        .get(
          `/api/getMasterTableList/${documentType}/${annexureType[tabValue]}`,
        )
        .then((data) => setData(data.data))
        .catch((err) => console.log(err.message));
    }
  }, [render, tabValue]);

  return (
    <div>
      <div style={{ paddingBottom: "3rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          {documentTitle[documentType]}
        </div>
        <Paper elevation={2} style={{ margin: "1rem 1.4rem" }}>
          <Tabs
            value={tabValue}
            onChange={(_, value) => {
              setTabValue(+value);
            }}
            aria-label="annexure tabs"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="ANNEXURE A" />
            <Tab label="ANNEXURE B" />
            <Tab label="ANNEXURE C" />
            <Tab label="ANNEXURE D" />
            <Tab label="ANNEXURE E" />
          </Tabs>
        </Paper>

        <Table
          style={{ marginTop: "3rem" }}
          className={classes.table}
          size="small"
        >
          {THead}
          {data && (
            <TBody
              {...{
                data,
                setRender,
                documentType,
              }}
            />
          )}
        </Table>
      </div>
    </div>
  );
}

function TBody({ data, setRender, documentType }) {
  const classes = useStyles();
  const { state } = useAuth();
  const permissions = usePermission(6);

  function removeDocument(id) {
    if (window.confirm("Are you sure you want to delete this Part?")) {
      axios
        .delete(`/api/deleteMasterFormat/${documentType}/${id}`)
        .then(() => {
          alert(`${documentType} deleted successfully.`);
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }
  return (
    <TableBody>
      {data.map((item, index) => (
        <React.Fragment key={item._id}>
          <TableRow className={classes.rootRow}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{item.annexure_type}</TableCell>
            <TableCell align="center">
              {item.revision_no > 0 ? "Revised" : "Original"}
            </TableCell>
            <TableCell align="center">{item.revision_no}</TableCell>

            <TableCell align="center">
              {viewIconButton(documentType, item)}
              {EditIconButton(state, documentType, item)}
              {permissions?.includes("delete") && (
                <Tooltip title="Delete" arrow>
                  <IconButton
                    size="small"
                    style={{ marginLeft: "0.5rem" }}
                    onClick={() => removeDocument(item._id)}
                  >
                    <DeleteIcon
                      style={{
                        fontSize: "1.4rem",

                        color: "red",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))}
    </TableBody>
  );
}

export default MasterControlFmeaPfdList;
