import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import ControlPlanPart from "./ControlPlanPart";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "../../../components/Control/useQuery";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { Add, SyncAlt } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import lodash from "lodash";
import ECNModal from "../ECNModal";
import useConfirm from "../../../components/Control/useConfirm";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SaveIcon from "@material-ui/icons/Save";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { DatePicker } from "@material-ui/pickers";
import cpGrid from "./createCPGrid.module.css";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(2),
    },
  },
}));

const CreateControlPlan = () => {
  const { partId, id } = useParams();
  const history = useHistory();
  // state come from annexure management
  const { state } = useLocation();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");

  const [render, setRender] = useState(false);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [controlPlanData, setControlPlanData] = useState({
    processes: [],
  });
  const classes = useStyles();
  const [prevControlPlanData, setPrevControlPlanData] = useState(null);
  const [isECNModal, setIsECNModal] = useState(false);
  const [expandState, setExpandState] = useState([]);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PART",
    "Revision no. already exists! Are you sure you want to overwrite?",
    // "Can not write changes to existing revision number. Please increment the revision number to proceed",
  );

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?",
  );

  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?",
  );
  const [DialogUpdateCpData, confirmUpdateCpData] = useConfirm(
    "SAVE",
    "Are you sure you want to save?",
  );

  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    "REMOVE",
    "Are you sure you want to remove this process?",
  );
  const [DialogCopyProcess, confirmCopyProcess] = useConfirm(
    "COPY PROCESS",
    "Are you sure you want to copy processes from PFD?",
  );

  useEffect(() => {
    axios
      .get(`/api/get/controlPlanPart/${partId}/${id}`)
      .then((res) => {
        if (res.data) {
          setControlPlanData(res.data);
          setPrevControlPlanData(lodash.cloneDeep(res.data));
        }
      })
      .catch((err) => console.log(err));
  }, [useEffectRender]);
  useEffect(() => {
    if (controlPlanData._id && state) {
      axios
        .put(`/api/readFromAnnexure`, {
          controlPlan: controlPlanData,
          annexure: state,
        })
        .then((res) => {
          if (res.data) {
            setControlPlanData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [prevControlPlanData]);

  const writeToAnnexure = () => {
    return new Promise(async (resolve, reject) => {
      axios
        .put(`/api/writeToAnnexure/${controlPlanData.annexure_id}`, state)
        .then((res) => {
          if (res.data) {
            setRender((prev) => !prev);
            alert("Annexure update successfully");
            resolve({ msg: "Annexure update successfully" });
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const handleControlPlanImport = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post("/api/import/controlPlan", formData)
      .then((res) => {
        const uploadData = res.data.newPart.processes;
        uploadData.forEach(
          (item, index) =>
            (item.s_no = controlPlanData.processes.length + index + 1),
        );
        controlPlanData.processes = [
          ...controlPlanData.processes,
          ...uploadData,
        ];
        e.target.value = "";
        setRender((prev) => !prev);
      })
      .catch((err) => {
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            "Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.",
          );
        }
      });
  };

  const handleControlPlanInputs = (e) => {
    controlPlanData[e.target.name] = e.target.value;
    setRender((prev) => !prev);
  };

  const handleControlPlanSubmit = async () => {
    if (controlPlanData._id) {
      let isUpdate = false;
      let isPost = false;
      const isRev = await axios
        .get(
          `/api/get/isrevno/controlplan/${partId}/${controlPlanData.revision_no}`,
        )
        .catch((err) => console.log(err));
      if (isRev.data.status === "success") {
        if (id) {
          isUpdate = await confirmUpdate();
        } else {
          isUpdate = true;
        }
      }
      if (isRev.data.status === "fail") {
        isPost = true;
      }
      if (isUpdate) {
        let isSave = await confirmUpdateCpData();
        if (isSave) {
          axios
            .put(
              `/api/update/controlPlanPart/${controlPlanData._id}`,
              controlPlanData,
            )
            .then(async () => {
              alert("Control Plan changes saved successfully");
              if (controlPlanData.annexure_id) {
                await writeToAnnexure();
              }
            })
            .catch((err) => console.log(err));
        }
      }
      if (isPost) {
        setIsECNModal(true);
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        controlPlanData.part_id = partId;
        controlPlanData.revision_no = 0;
        axios
          .post("/api/create/controlPlanPart", controlPlanData)
          .then(async () => {
            alert("Control Plan submitted successfully");
            if (controlPlanData.annexure_id) {
              await writeToAnnexure();
            }
            setUseEffectRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleCopyProcesses = async () => {
    let isCopy = await confirmCopyProcess();
    if (isCopy) {
      axios.get(`/api/get/pfdPart/${partId}/${id}`).then((res) => {
        const data = res.data;
        const extractProcess = data.processes.map(
          ({ process_no, process_name }, index) => {
            return {
              s_no: controlPlanData.processes.length + index + 1,
              process_no,
              process_name,
              specs: [],
            };
          },
        );

        controlPlanData.processes = [
          ...controlPlanData.processes,
          ...extractProcess,
        ];
        setRender((prev) => !prev);
      });
    }
  };
  const handleSubmitRevision = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    delete controlPlanData._id;
    delete controlPlanData.createdAt;
    delete controlPlanData.updatedAt;
    delete controlPlanData.__v;
    controlPlanData.processes.forEach((process) => {
      delete process._id;
      process.specs.forEach((spec) => delete spec._id);
    });
    revisionData = Object.keys(revisionData)
      .map((key) => {
        if (!revisionData[key].tolerance_change) {
          return {
            rev_no: controlPlanData.revision_no,
            summary: revisionData[key].value,
            reason: revisionData[key].reason,
            section: revisionData[key].section,
            rev_date: controlPlanData.date_review,
          };
        } else {
          return;
        }
      })
      .filter((obj) => obj !== undefined);
    controlPlanData.revisionData = [
      ...controlPlanData.revisionData,
      ...revisionData,
    ];
    await axios
      .put(`/api/setIsLatestRevsionFalse/controlplan/${partId}`)
      .catch((error) => console.log(error));
    controlPlanData.isLatestRevision = true;
    controlPlanData.date_review = controlPlanData.date_review || new Date();
    axios
      .post("/api/create/controlPlanPart", controlPlanData)
      .then(async () => {
        alert("control plan submitted successfully");
        if (controlPlanData.annexure_id) {
          await writeToAnnexure();
        }
        history.push(`/revisedTable/controlplan/${partId}`);
      })
      .catch((err) => console.log(err));
  };

  const removeProcess = async (index) => {
    const isDelete = await confirmRemoveProcess();
    if (isDelete) {
      const values = { ...controlPlanData };
      values.processes.splice(index, 1);
      setControlPlanData(values);
    }
  };
  const handleChangeProcess = (e, index) => {
    controlPlanData.processes[index][e.target.name] = e.target.value;
    if (e.target.name === "s_no") {
      controlPlanData.processes.sort((a, b) => Number(a.s_no) - Number(b.s_no));
      setRender((prev) => !prev);
    }
  };

  const applyToAllCategory = async (revisionData) => {
    if (lodash.isEmpty(revisionData)) {
      alert("you can't create revision");
      return;
    }
    if (
      window.confirm("Are you sure you want to apply changes to all parts?")
    ) {
      await axios
        .post(
          `/api/controlplan/ecn/applyToAll/?partId=${controlPlanData.part_id._id}`,
          { revisionData, state },
        )
        .then((res) => {
          handleSubmitRevision(revisionData);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
            justifyContent: "space-between",
          }}
        >
          EDIT CONTROL PLAN
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {/* <div style={{ marginTop: "9px", marginRight: "0.5rem" }}> */}
            {/*   {" "} */}
            {/*   <input */}
            {/*     id="contained-button-file" */}
            {/*     type="file" */}
            {/*     name="excelFile" */}
            {/*     accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" */}
            {/*     onChange={handleControlPlanImport} */}
            {/*     hidden */}
            {/*   /> */}
            {/*   <label htmlFor="contained-button-file"> */}
            {/*     <Button */}
            {/*       variant="outlined" */}
            {/*       component="span" */}
            {/*       color="secondary" */}
            {/*       size="large" */}
            {/*       style={{ backgroundColor: "#fff" }} */}
            {/*     > */}
            {/*       <CloudUploadIcon style={{ marginRight: "1rem" }} /> */}
            {/*       Upload CP */}
            {/*     </Button> */}
            {/*   </label> */}
            {/* </div> */}
            {controlPlanData._id ? (
              <Link
                to={{
                  pathname: `/control_plan/${partId}/${controlPlanData._id}`,
                }}
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  //   onClick={() => setOpenModal(true)}
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "0.5rem",
                    color: "#054A29",
                  }}
                  startIcon={<VisibilityIcon />}
                >
                  View CP
                </Button>
              </Link>
            ) : (
              ""
            )}

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleControlPlanSubmit}
              style={{
                backgroundColor: "#003566",
                marginRight: "0.5rem",
              }}
              startIcon={<SaveIcon />}
            >
              Save & Continue
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              //   onClick={() => setOpenModal(true)}
              style={{
                backgroundColor: "red",
                marginRight: "0.5rem",
              }}
              startIcon={<CloseIcon />}
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            >
              Close
            </Button>
          </div>
        </div>
        <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
      </main>
      <div style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <div className={cpGrid.cpInputGrid}>
          <Input
            // className="input_field_width"
            type="Number"
            name="revision_no"
            placeholder="Revision Number"
            onChange={(e) => handleControlPlanInputs(e)}
            disabled={!id}
            value={controlPlanData.revision_no}
          />
          <Input
            // className="input_field_width"
            type="text"
            name="control_plan_no"
            placeholder="Control Plan Number"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.control_plan_no}
          />

          <FormControl
            variant="filled"
            className="select-field"
            // style={{
            //   marginLeft: "0.5rem",
            //   width: "20rem",
            //   minWidth: "10rem",
            // }}
          >
            <InputLabel>Select Type</InputLabel>
            <Select
              name="part_type"
              value={controlPlanData.part_type}
              key={controlPlanData.part_type}
              onChange={(e) => handleControlPlanInputs(e)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="prototype">Prototype</MenuItem>
              <MenuItem value="pre-Launch">Pre-Launch</MenuItem>
              <MenuItem value="production">Production</MenuItem>
            </Select>
          </FormControl>

          <Input
            // className="input_field_width"
            type="text"
            name="remarks"
            placeholder="Remarks"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.remarks}
          />
          {/* <Input
            // className="input_field_width"
            type="text"
            name="latest_change_level"
            placeholder="Latest Change Level"
            onChange={(e) => handleControlPlanInputs(e)}
            value={controlPlanData.latest_change_level}
          /> */}
          {/* <DatePicker
            inputVariant="filled"
            name="supplier_plant_approval_date"
            style={{ paddingLeft: "0.6rem" }}
            label="Supplier / Plant Approval Date"
            value={controlPlanData.supplier_plant_approval_date || null}
            onChange={(date) => {
              controlPlanData.supplier_plant_approval_date = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          /> */}
          <DatePicker
            inputVariant="filled"
            name="other_approval_date"
            style={{ paddingLeft: "0.6rem" }}
            label="Other Approval Date"
            value={controlPlanData.other_approval_date || null}
            onChange={(date) => {
              controlPlanData.other_approval_date = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
          <DatePicker
            inputVariant="filled"
            name="customer_other_approval_date"
            style={{ paddingLeft: "0.6rem" }}
            label="Other Approval Date Customer"
            value={controlPlanData.customer_other_approval_date || null}
            onChange={(date) => {
              controlPlanData.customer_other_approval_date = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
          <DatePicker
            inputVariant="filled"
            name="date_review"
            style={{ paddingLeft: "0.6rem" }}
            label="Date (Review)"
            value={controlPlanData.date_review || null}
            onChange={(date) => {
              controlPlanData.date_review = date;
              setRender((prev) => !prev);
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>
        <Wrapper
          style={{
            marginLeft: "0.76rem",
            marginTop: "1rem",
            gap: "1rem",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              setControlPlanData((prev) => ({
                ...prev,
                other_approval_date: null,
                customer_other_approval_date: null,
                date_review: null,
              }))
            }
            startIcon={<ClearAllIcon />}
          >
            RESET DATE
          </Button>
          <p style={{ fontSize: "0.8rem", margin: 0 }}>
            Click to clear/reset all dates
          </p>
        </Wrapper>

        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>Process</span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "175px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                marginRight: "0.2rem",
              }}
              onClick={() => handleCopyProcesses()}
              justify="center"
            >
              <FileCopyIcon style={{ fontSize: "1.4rem" }} />

              <span>Copy Process </span>
            </Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "175px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                marginRight: "0.2rem",
              }}
              onClick={() => {
                const values = { ...controlPlanData };
                values.processes.push({
                  s_no: controlPlanData.processes.length + 1,
                  process_no: "",
                  process_name: "",
                  specs: [],
                });
                setControlPlanData(values);

                setExpandState((prev) => {
                  prev[controlPlanData.processes.length - 1] = true;
                  return [...prev];
                });
              }}
              justify="center"
            >
              <Add />
              <span>Add Process</span>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        {controlPlanData.processes.map((process, index) => (
          <ControlPlanPart
            key={uuidv4()}
            {...{
              controlPlanData,
              setControlPlanData,
              removeProcess,
              process,
              index,
              handleChangeProcess,
              setExpandState,
              expandState,
            }}
          />
        ))}
      </div>
      <DialogUpdate isSubmitContent={true} />
      <DialogSave isSubmitContent={true} />
      <DialogUpdateCpData isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogRemoveProcess />
      <DialogCopyProcess />
      {isECNModal && (
        <ECNModal
          openPopup={isECNModal}
          closeModal={() => setIsECNModal(false)}
          partData={controlPlanData}
          documentType="controlplan"
          ecnTitle="ECN - CONTROL PLAN CHANGES"
          partDataForRevision={prevControlPlanData}
          submit={handleSubmitRevision}
          {...{ applyToAllCategory }}
        />
      )}
    </>
  );
};

export default CreateControlPlan;
