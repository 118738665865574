import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { Wrapper } from "../../components/Control/Flexbox";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import TableReadEditCell from "../../components/TableReadEditCell";
import Styled from "styled-components";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	border: 1px solid black;


    th{
        font-size: 13px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }
`;

const AddEditAnnexure = ({ setIsAddEditModal, id, isView }) => {
  const [data, setData] = useState({});
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "DRAFT PART",
    `Are you sure you want to ${id ? "update" : "save"} this part?`,
  );

  function handleChange(e) {
    let value = e.target.value;
    setData((prev) => ({ ...prev, [e.target.name]: value }));
  }

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneMasterModule/data/${id}`)
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setData({});
    }
  }, [id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!data.item_no) {
      alert("Item No is mandatory");
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/updateMasterModule/data/${id}`, data)
        .then((res) => {
          if (res.data) {
            setIsAddEditModal(false);
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/customerMaster/create", data)
        .then((res) => {
          if (res.data) {
            setIsAddEditModal(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
          marginTop: "0.2rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <Wrapper
          style={{
            marginLeft: "11px",
          }}
        >
          <div>
            <DatePicker
              value={data.date_created || null}
              label="Date"
              disabled={isView}
              className="date-field"
              inputVariant="filled"
              format="dd/MM/yyyy"
              clearable={true}
              onChange={(date) => {
                setData((prev) => ({ ...prev, date_created: date }));
              }}
            />
          </div>
        </Wrapper>
        <Table>
          <tr>
            <td>Item No</td>
            <td>
              <TableReadEditCell
                type="text"
                name="item_no"
                disabled={data._id}
                value={data.item_no}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td>Item Description</td>
            <td>
              <TableReadEditCell
                type="text"
                name="item_description"
                value={data.item_description}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
          <tr>
            <td>Weight/pc (kgs)</td>
            <td>
              <TableReadEditCell
                type="number"
                name="weight_pc"
                step="0.0000001"
                value={data.weight_pc}
                {...{ isView, handleChange }}
              />
            </td>
          </tr>
        </Table>
        <div
          className="btn_save"
          style={{
            position: "absolute",
            right: "150px",
            top: "9px",
          }}
        >
          {!isView && (
            <Button
              type="submit"
              variant="outlined"
              color="white"
              size="large"
              style={{ color: "#e9e9e9" }}
              startIcon={<PublishIcon />}
            >
              {id ? "UPDATE" : "SUBMIT"}
            </Button>
          )}
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditAnnexure;
