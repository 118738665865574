import Styled from "styled-components";
import TableReadEditCell from "../../components/TableReadEditCell";
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	border: 1px solid black;


    th{
        font-size: 13px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100%;
        border: none;
        padding-left: 4px;
        height: 40px;
    }
`;

const heading = {
  "annexure-a": "ANNEXURE A",
  "annexure-b": "ANNEXURE B",
  "annexure-c": "ANNEXURE C",
  "annexure-d": "ANNEXURE D",
  "annexure-e": "ANNEXURE E",
};

const AnnexureDForm = ({ id, data, handleChange, isView, annexureType }) => {
  return (
    <section>
      <Table>
        <tr>
          <th
            colSpan={2}
            style={{
              fontSize: "1.2rem",
              fontWeight: 600,
              backgroundColor: "#1d1d1d",
              color: "white",
              border: "1px solid black",
              textAlign: "center",
            }}
          >
            {heading[annexureType]}
          </th>
        </tr>
        <tr>
          <td>Wire Diameter</td>
          <td>
            <TableReadEditCell
              required
              type="text"
              name="annex_d_wire_dia"
              value={data.annex_d_wire_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Wire Tensile</td>
          <td>
            <TableReadEditCell
              type="text"
              required
              name="annex_d_wire_tensile"
              value={data.annex_d_wire_tensile}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Shank Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              required
              name="annex_d_shank_dia"
              value={data.annex_d_shank_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Length</td>
          <td>
            <TableReadEditCell
              type="text"
              required
              name="annex_d_length"
              value={data.annex_d_length}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Head Dia</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_d_head_dia"
              required
              value={data.annex_d_head_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Head Thickness</td>
          <td>
            <TableReadEditCell
              type="text"
              required
              name="annex_d_head_thickness"
              value={data.annex_d_head_thickness}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Internal Diameter</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_d_internal_dia"
              required
              value={data.annex_d_internal_dia}
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Internal Depth</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_d_internal_depth"
              value={data.annex_d_internal_depth}
              required
              isView={isView}
              {...{ handleChange }}
            />
          </td>
        </tr>
        <tr>
          <td>Appearance</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_d_appearance"
              isView={isView}
              required
              {...{ handleChange }}
              value={data.annex_d_appearance}
            />
          </td>
        </tr>
        <tr>
          <td>Radius</td>
          <td>
            <TableReadEditCell
              type="text"
              name="annex_d_radius"
              isView={isView}
              required
              {...{ handleChange }}
              value={data.annex_d_radius}
            />
          </td>
        </tr>
      </Table>
    </section>
  );
};

export default AnnexureDForm;
