import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";
import { usePermission } from "../../components/usePermission";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";

import Input from "../../components/Control/Input";
import formatDate from "../../components/Control/formatDate";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import { useAuth } from "../../context/GlobalContext";

const RMPartListComponent = () => {
  const [data, setData] = useState([]);
  const { state } = useAuth();
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("item_desc");

  //confirm modal
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "DELETE PART",
    "Are you sure you want to delete this part?",
  );

  const fetchData = () => {
    axios
      .get(
        `/api/getAll/admin/rm/?usertype=${state.user.userType}&page=${page}&sortBy=${filter}&search=${search}`,
      )
      .then((res) => {
        setData(res.data?.result);
        setPerPage(res.data?.perPage);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 1000, [page, search, render, filter, render]);
  const permissions = usePermission(7);
  const deleteRM = async (id) => {
    const isDelete = await confirmUpdate();

    if (isDelete) {
      await axios.delete(`/api/removeRM/${id}`).then((res) => {
        if (res.data.status === "success") {
          alert("Part deleted successfully");
          setRender((prev) => !prev);
        }
      });
    }
  };
  return (
    <>
      <div className="divflex" style={{ fontSize: "24px" }}>
        RM RECEIVING/INCOMING
        <Wrapper>
          {permissions?.includes("edit") && state.user.userType === 2 && (
            <Link to="add/edit/rmPart" style={{ textDecoration: "none" }}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Add />}
              >
                New Part
              </Button>
            </Link>
          )}
        </Wrapper>
      </div>
      <TableContainer component={Paper}>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Part Number"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{
              marginRight: "1.5rem",
              marginTop: "0.8rem",
              flex: "0.2",
            }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="part_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="item_desc">A - Z</MenuItem>
              <MenuItem value="createdAt">Most Recent First</MenuItem>
              <MenuItem value="createdAt_old">Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table size="small">
          <TableHead>
            <TableRow style={{ padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Code
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Item Description
              </TableCell>

              <TableCell style={{ fontWeight: "bold" }} align="center">
                Status
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
              {state.user.userType === 2 && (
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Incoming / Breakload
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          {data && (
            <TableBody>
              {data.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center" width={10}>
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        overflow: "auto",
                        width: "20rem",
                        maxWidth: "20rem",
                      }}
                    >
                      {item.part_id?.item_no || item.item_code}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        overflow: "auto",
                      }}
                    >
                      {item.part_id?.item_description || item.item_desc}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.9rem",
                        outline: "none",
                        border: "none",
                        padding: "5px",
                        marginLeft: "0.5rem",
                        width: "150px",
                        textAlign: "center",
                        textTransform: "capitalize",

                        backgroundColor: item.isSubmitted
                          ? "#2b8a3e"
                          : "#FDD835",

                        color: item.isSubmitted ? "#fff" : "#000",
                      }}
                    >
                      {item.isSubmitted ? "SUBMITTED" : "DRAFT"}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.createdAt)}
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", width: "200px" }}
                    >
                      {state.user.userType === 2 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "250px",
                            width: "220px",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="View" arrow>
                            <Link to={`/view/rm/${item._id}`}>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.5rem",
                                }}
                              >
                                <VisibilityIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#003566",
                                  }}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                          {permissions?.includes("edit") && (
                            <Tooltip title="Edit" arrow>
                              <Link to={`/add/edit/rmPart/${item._id}`}>
                                <IconButton
                                  size="small"
                                  style={{
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  <EditIcon
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "black",
                                    }}
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                          {permissions?.includes("delete") && (
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                size="small"
                                onClick={() => deleteRM(item._id)}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      ) : (
                        <div>
                          <Tooltip title="ADD" arrow>
                            <Link
                              to={`/incoming/breakload/${item._id}?item_desc=${encodeURI(item.part_id?.item_description)}&item_code=${encodeURI(item.part_id?.item_no)}`}
                            >
                              <IconButton size="small">
                                <Add
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#003566",
                                  }}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </div>
                      )}
                    </TableCell>
                    {state.user.userType === 2 && (
                      <TableCell align="center">
                        <Link
                          to={`incoming/breakload/${item._id}?item_desc=${encodeURI(item.part_id?.item_description)}&item_code=${encodeURI(item.part_id?.item_no)}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              backgroundColor: "#003566",
                            }}
                            startIcon={<VisibilityIcon />}
                          >
                            View Database
                          </Button>
                        </Link>
                      </TableCell>
                    )}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
      <DialogUpdate />
    </>
  );
};

export default RMPartListComponent;
