import React, { useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Block, Search } from "@material-ui/icons";
import UpdateIcon from "@material-ui/icons/Update";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import CategoryIcon from "@material-ui/icons/Category";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { usePermission } from "../../components/usePermission";
import Input from "../../components/Control/Input";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import AddEditAnnexure from "./AddEditAnnexure";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import UploadAnnexure from "./UploadAnnexure";
import RevisionLog from "./RevisionLog";
import PublishAnnexure from "./PublishAnnexure";
const statusObj = {
  draft: 1,
  publish: 2,
};
const styleObj = {
  border: "0.5px solid #ffa8a8",
  padding: "5px",
  fontSize: "12px",
  background: "#f8f9fa",
  borderRadius: "5px",
};
const AnnexureManagement = () => {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const annexureId = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("annexure-a");
  const [isView, setIsView] = useState(false);
  const [isAddEditModal, setIsAddEditModal] = useState(false);
  const [isRevsionModal, setIsRevisionModal] = useState(false);
  const [dropDownRender, setDropDownRender] = useState(false);
  const [isAnnexureUpload, setIsAnnexureUpload] = useState(false);
  const [isPublishModal, setIsPublishModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const errorObject = useRef([]);
  const controlplanId = useRef();
  //confirm modal
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE STATUS",
    "Are you sure you want to update this?",
  );

  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    "REMOVE",
    "Are you sure you want to remove this process?",
  );
  const permissions = usePermission(2);
  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllAnnexure?page=${page}&sortBy=${filter}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    isAddEditModal,
    isAnnexureUpload,
    isPublishModal,
  ]);

  async function deleteAnnexure(id) {
    const isDelete = await confirmRemoveProcess();
    if (isDelete) {
      await axios.delete(`/api/remove/annexure/${id}`);
      setRender((prev) => !prev);
    }
  }

  const handleChangeRequestStatus = (e, index) => {
    data[index].isSubmitted = null;
    data[index].isSubmittedNo = e.target.value;
    setDropDownRender((prev) => !prev);
  };

  const excelDownload = async () => {
    await axios
      .get(`/api/editExcel/annexureManagement?annexure_type=${filter}`, {
        responseType: "blob",
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filter}.xlsx`);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const publishAnnexure = async () => {
    let isConfirm = await confirmUpdate();
    if (isConfirm) {
      axios
        .post(`/api/publish/annexure`, {
          parts: data,
          controlData: {},
          pfdData: {},
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert("Status Change successfully");
            setRender((prev) => !prev);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          ANNEXURE MANAGEMENT
          <Wrapper>
            {permissions?.includes("edit") && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  background: "#fafafa",
                  marginRight: "1rem",
                  color: "#04402e",
                }}
                onClick={() => setIsAnnexureUpload(true)}
                startIcon={<CloudUploadIcon />}
              >
                Upload Annexure
              </Button>
            )}
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                background: "#76102a",
                marginRight: "1rem",
              }}
              onClick={() => {
                excelDownload();
              }}
              startIcon={<CategoryIcon />}
            >
              Download Annexure
            </Button>
            {permissions?.includes("edit") && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  annexureId.current = null;
                  setIsView(false);
                  setIsAddEditModal(true);
                }}
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                  marginRight: "1rem",
                }}
                startIcon={<Add />}
              >
                New Part
              </Button>
            )}
          </Wrapper>
        </div>

        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Part Number"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="annexure-a">Annexure A</MenuItem>
                <MenuItem value="annexure-b">Annexure B</MenuItem>
                <MenuItem value="annexure-c">Annexure C</MenuItem>
                <MenuItem value="annexure-d">Annexure D</MenuItem>
                <MenuItem value="annexure-e">Annexure E</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Control No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Number/Item Code
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  CP Rev No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Created At
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold", width: "120px" }}
                >
                  Status
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Revision Log
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.control_no}</TableCell>
                    <TableCell align="center">{item.part_number}</TableCell>
                    <TableCell align="center">
                      {item.control_id && item.control_id?.revision_no}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.createdAt)}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          fontSize: "0.9rem",
                          outline: "none",
                          border: "none",
                          padding: "5px",
                          marginLeft: "0.5rem",
                          width: "10rem",
                          textAlign: "center",
                          textTransform: "uppercase",
                          ...(item.isSubmitted === "unpublish" && {
                            backgroundColor: "#FDD835",
                          }),
                          ...(item.isSubmitted === "publish" && {
                            backgroundColor: "#2b8a3e",
                            color: "#fff",
                          }),
                        }}
                      >
                        {item.isSubmitted}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", width: "220px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          minWidth: "250px",
                          width: "220px",
                          alignItems: "center",
                        }}
                      >
                        {permissions?.includes("edit") && (
                          <Tooltip title="Edit Part" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                annexureId.current = item._id;
                                setIsView(false);
                                setIsAddEditModal(true);
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Quick View" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                            onClick={() => {
                              annexureId.current = item._id;
                              setIsView(true);
                              setIsAddEditModal(true);
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={item.part_id}
                          size="small"
                          onClick={(e) => {
                            annexureId.current = item._id;
                            setIsPublishModal(true);
                          }}
                          startIcon={
                            <UpdateIcon style={{ fontSize: "1.4rem" }} />
                          }
                        >
                          Publish
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {item.control_id ? (
                        <Tooltip title="Quick View" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                            onClick={() => {
                              controlplanId.current = `${item.part_id}/${item.control_id._id}`;
                              setIsRevisionModal(true);
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Block" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                          >
                            <Block
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <DialogUpdate />
        <DialogRemoveProcess />
        <Modal
          openPopup={isPublishModal}
          title="PUBLISH ANNEXURE"
          closeModal={() => setIsPublishModal(false)}
        >
          <PublishAnnexure
            id={annexureId.current}
            annexureType={filter}
            setIsPublishModal={setIsPublishModal}
          />
        </Modal>

        {/* <DialogDelete /> */}
        <Modal
          openPopup={isAddEditModal}
          title="Add Annexure"
          closeModal={() => setIsAddEditModal(false)}
        >
          <AddEditAnnexure
            id={annexureId.current}
            annexureType={filter}
            setAnnexureType={setFilter}
            isView={isView}
            setIsAddEditModal={setIsAddEditModal}
          />
        </Modal>
        <Modal
          openPopup={isAnnexureUpload}
          title="Upload Annexure"
          closeModal={() => setIsAnnexureUpload(false)}
        >
          <UploadAnnexure
            setIsAnnexureUpload={setIsAnnexureUpload}
            {...{ errorModal, setErrorModal, errorObject }}
          />
        </Modal>
        <Modal
          openPopup={isRevsionModal}
          title="Revision Log"
          closeModal={() => setIsRevisionModal(false)}
        >
          <RevisionLog id={controlplanId.current} />
        </Modal>
        <Modal
          openPopup={errorModal}
          title="ERRORS LOGS"
          closeModal={() => setErrorModal(false)}
        >
          {
            <table style={{ width: "100%" }}>
              <thead>
                <th style={styleObj}>Control No.</th>
                <th style={styleObj}>Item Code</th>
                <th style={styleObj}>ERROR</th>
              </thead>
              <tbody>
                {errorObject.current?.map((item, index) => (
                  <tr>
                    <td style={styleObj}>{item?.control_no}</td>
                    <td style={styleObj}>{item?.part_number}</td>
                    <td style={styleObj}>{item?.err}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </Modal>
      </main>
    </>
  );
};

export default AnnexureManagement;
