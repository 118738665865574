import { Paper, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "../../context/GlobalContext";
import AdminPir from "./AdminPir";
import SupervisorPir from "./SupervisorPir";

function PirTableList() {
  const { state } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PROCESS INSPECTION REPORTS
      </div>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="fullWidth"
          textColor="primary"
        >
          <Tab label="PENDING  REPORTS" />
          <Tab label="APPROVED  REPORTS" />
        </Tabs>
      </Paper>

      {state.user.userType === 2 ? (
        <AdminPir {...{ tabValue }} />
      ) : (
        <SupervisorPir {...{ tabValue }} />
      )}
    </>
  );
}

export default PirTableList;
